 /*========================MEDIA QUERY==============================*/
 @mixin mobile{
    @media screen and (max-width: 600px) {
        @content;
    }
}
@mixin tablet {
    @media screen and (max-width: 768px) {
        @content;
    }
}
@mixin tablet2 {
    @media screen and (max-width: 900px) {
        @content;
    }
}
@mixin laptop {
    @media screen and (max-width: 1220px) {
        @content;
    }
}
@mixin laptop2 {
    @media screen and (max-width: 1100px) {
        @content;
    }
}


.latest-container{
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 80%;
    margin: auto auto;
    margin-top: 5%;
    padding: 0;
    z-index: 1;
    overflow: visible;
    background: var(--SecondaryColor);
    @include laptop2{
        width: 90%;
       }

    .latest-head{
        display: flex;
        gap: 0.5rem;
        flex: start;
        align-items: center;
        background: var(--whiteColor);
        padding: 5% 0;
        span{
            background: #ecb604;
            height: 40px;
            width: 7px;
            margin-left: 5px;
        }
    }

    .letest-latest{
        display: flex;
        flex-direction: row;
        gap: 2.5rem;
        margin: 0;
        padding: 0;
        @include tablet2{
            display: grid;
            grid-template-columns: auto auto;
           }
           @include tablet{
            display: grid;
            grid-template-columns: auto ;
           }
        .latest-content{
            display: flex;
            flex-direction: column;

            .latest-img{
                width: 100%;
                height: auto;
            }
            
            &:hover{
                box-shadow: rgba(160, 155, 155, 0.8) 0px 40px 60px -16px,
                rgba(158, 150, 150, 0.72) 0px 30px 22px -10px;
                transform: scale(1.05);
                transition-duration: 300ms;
                .latest-text{
                    h2{
                        text-decoration: underline solid #ecb604;
                        text-decoration-thickness: 7px;
                    }
                }
            }
            .latest-text{
                text-align: left;
                padding-left: 10%;
            }
        }
        
    }
    .latest-btn{
        display: flex;
        margin-top: 10%;
        width: 10rem;
        padding: 0.8rem 1rem;
        justify-content: center;
        border: 5px solid #ecb604;
        font-weight: 700;
        a{
            text-decoration: none;
            color: #000;
        }
        span{
            margin-left: 1rem;
            font-weight: 700;
        }
        &:hover{
            background: #ecb604;
        }
    }
    
}