
 .flex{
  display: flex;
  align-items: center;
 }
@mixin tablet {
    @media screen and (max-width: 768px) {
        @content;
    }
}
@mixin tablet2 {
  @media screen and (max-width: 840px) {
      @content;
  }
}
.navBar{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 1;

    .navBarOne{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: var(--SecondaryColor);
        padding: 1rem;
        .icon{
          font-size: 1.5rem;
          &:hover{
            color: var(--PrimaryColor);
        }
        @include tablet{
          display: none;
        }
      }
        .atb{
            display: flex;
            align-items: center;
            gap: 1rem;
            @include tablet{
              gap: 0.5rem;
            }
            h3{
                color: var(--blackColor);
                background: #09da47ee;
                padding: 1rem;
                cursor: pointer;
                @include tablet{
                  padding: 0.4rem;
                }
                &:hover{
                   background-color: var(--PrimaryColor);
                   color: var(--whiteColor);
                    font-weight: 700;
                }
            }
        }
    }
   
    .navBarTwo{
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.3rem 0.5rem;
        transition: 0.3s ease-in-out;
        background: #f89b0f;
        z-index: 2;
    

        .LogoDiv{
           display: flex;
           align-items: center;
            .Logo{
                height: auto;
                width: 70%;
                border-radius: 50%;
            }
            h1{
                margin-left: 2rem;
                @include tablet2{
                 margin-left: 0.5rem;
                }
            }
        }
          #listbar{
            .icon{
                font-size: 1.5rem;
                display: none;
                @include tablet{
                    display: block;
                }
            }
            .menu{
              display: flex;
              align-items: center;
              
              gap: 2rem;
              text-align: center;
              .listItem {
                  display: flex;
                  padding: .5rem 0;
                  list-style: none;
                  cursor: pointer;
                  color: var(--whiteColor);
                  align-items: center;
                  gap: 0rem;
                   a{
                    text-decoration: none;
                    color: #000;
                    display: flex;
                    padding: .5rem 0;
                    cursor: pointer;
                    color: var(--blackColor);
                    font-weight: 600;
                    @include tablet2{
                      padding: .3rem 0;
                    }
                    @include tablet{
                      padding: 0rem 0;
                    }
                    span{
                      font-size: 13px;
                      letter-spacing: 1.42px;
                      position: relative;
                      &:after{
                          content: "";
                          height: 2px;
                          background: var(--HoverColor);
                          position: absolute;
                          left: 0;
                          right: 0;
                          bottom: -6px;
                          opacity: 0;
                          transform-origin: left center;
                          transition: all 250ms ;
                          transform: scaleX(0);
                        }
                        &:hover{
                          color: var(--HoverColor);
                          font-weight: 700;
                          &:after{
                              transform: scaleX(1);
                              opacity: 1;
                              }
                      }
                   }
                }
                 h2{
                  padding: 1rem 0;
                  font-size: 25px;
                  color: var(--blackColor);
                  color: #f88f05;
                 }
              } 
            }
            .btnOne{
              display: none;
              justify-content: center;
              margin: auto;
              margin-top: 1rem;
              margin-bottom: 0.7rem;
              padding: 0.7rem 1rem;
              background: var(--PrimaryColor);
              border: none;
              outline: none;
              border-radius: 3rem;
              cursor: pointer;
              color: var(--whiteColor);
              @include tablet{
                display: block;
            }
              a{
                text-decoration: none;
                color: var(--whiteColor);
                font-weight: 500;
              }
              &:hover{
                background: var(--HoverColor);
              }
            }
          }
         
            .btnTwo{
              display: flex;
              align-items: center;
              padding: 0.7rem 1.5rem;
              background: var(--PrimaryColor);
              border: none;
              outline: none;
              border-radius: 3rem;
              cursor: pointer;
              color: var(--whiteColor);
              a{
                text-decoration: none;
                color: var(--whiteColor);
                font-weight: 500;
              }
          
              &:hover{
                background: var(--HoverColor);
              }
            }
            .toggleIcon{
                display: none;
                .icon{
                    font-size: 1.5rem;
                    font-size: 30px;
                    color: var(--blackColor);
                }
            }
        

        @media screen and (max-width: 768px) {
            .navBarMenu{
                position: absolute;
                background: var(--SecondaryColor);
                padding: 0rem;
                border-radius: 10px;
                top: 1rem;
                right: -50%;
                width: 70%;
                transform: translateX(50%);
                z-index: 2000;
                overflow: visible;
                box-shadow: rgba(0,0,0, 0.1) 0px 10px 15px -3px,
                rgba(0,0,0, 0.05) 0 4px 6px -2px;
                border: 3px solid --whiteColor;
                transition: .5s ease-in-out;
                    
                    .icon{
                        font-size: 1.5rem;
                        position: absolute;
                        right: 10% ;
                        margin-bottom: 1rem;
                        
                        &:hover{
                            background-color: #da2828;
                            color: #fff;
                            border-radius: 50%;
                            
                        }
                     }
                   
                
                .menu{
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    justify-content: center;
                    
                    .listItem{
                        
                        cursor: pointer;
                        color: var(--blackColor);
                        font-weight: 700;
                        padding: 0rem 1.3rem;
                        h2{
                            display: none;
                        }
                          a{
                            color: #000;
                            span{
                            color: #000;
                            }
                          }
                    }
                }
                .btnOne{
                    display: block;
                    padding: 0.7rem 1.5rem;
                    background: var(--PrimaryColor);
                    border: none;
                    outline: none;
                    border-radius: 3rem;
                    cursor: pointer;
                    color: var(--whiteColor);
                    a{
                      color: var(--whiteColor);
                      font-weight: 500;
                  }
              
                  &:hover{
                      background: var(--HoverColor);
                  }
                }
            }
            .btnTwo{
                display: none;
            }
            .toggleIcon{
                display: flex;
                .icon{
                    font-size: 1.5rem;
                    font-size: 30px;
                    color: var(--blackColor);
                }
            }
            .showNavBar{
                right: 50%;
                transition: .5s ease-in-out;
            }
        }
        
    }
}
