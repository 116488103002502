
@mixin mobile{
  @media screen and (max-width: 600px) {
      @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 1024px) {
      @content;
  }
}


  
.contact{
  margin-top: 8rem;
  width: 100%;
  height: 100%;
  @include laptop{
    margin-top: 6rem;
  }
  h3{
    text-align: center;
    color: #000;
    font-weight: 700;
  }
  h2{
    margin-bottom: 3rem;
    margin-top: 2rem;
    text-align: center;
    font-weight: 700;
    color: var(--color-primary);
    &:hover{
      text-decoration: underline solid #f38d07;
      text-decoration-thickness: 7px;
    }
  }

.contact_container{
    margin: 1rem auto;
    padding: 1rem auto;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 5%;
    @include laptop{
      width: var(--container-width-md);
      grid-template-columns: 1fr;
        gap: 2rem;
    }
    @include mobile{
      width: var(--container-width-sm);
    }
.contact_options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin: 1rem 2rem 0 1rem;
    padding: 1rem 2rem 0 1rem;

    .contact_option{
      background: #f38d07;
      padding: 1.2rem;
      border-radius: 1.2rem;
      text-align: center;
      border: 1px solid transparent;
      transition: var(--transition);

      &:hover{
        background: transparent;
        border-color: var(--color-primary);
        background: var(--color-light);
        h5{
            text-align: center;
            color: var(--color-light);
            color: #f38d07;
        }
    }
    .contact_option-icon{
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
  }
  h4{
    font-weight: 500;
  }
  h5{
    text-align: center;
    color: var(--color-light);
    font-weight: 500;
  }
   a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
    color: #000;
    transition: var(--transition);

    &:hover{
       color: var(--color-white);
    }
  }
  
  }
}

/*========================= FORM ===========================*/
form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 100%;
    margin: 1rem 2rem 0 1rem;
    padding: 1rem 2rem 0 1rem;
    

input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
    }
    .btn{
      width: max-content;
      display: inline-block;
      padding: 0.75rem 1.2rem;
      border-radius: 0.4rem;
      cursor: pointer;
      border: 1px solid var(--color-primary);
      transition: var(--transition);
      background-color:  var(--color-primary);
      &:hover{
        background: var(--color-white);
        color: var(--color-bg);
        border-color: var(--color-primary);
      }
    }
   
  }
 }
}
