footer{
    background:  #1a1616;
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
    .footer_logo{
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 2rem;
        display: block;
        color: #fff; 
        &:hover{
            text-decoration: underline solid #faab02;
            text-decoration-thickness: 7px;
        } 
    }
    .permanentlinks{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;
        margin: 0 auto 3rem;
        li{
            list-style: none;
            color: #fff;
        a{
            color: #fff;
            text-decoration: none;
            
            &:hover{
                text-decoration: underline solid #faab02;
                text-decoration-thickness: 5px;
            }
        }
      }
    }
    .footer_socials{
        display: flex;
        justify-content: center;
        gap: 1rem;
        margin-bottom: 4rem;
        a{
            background: #faab02;
            color: #000;
            padding: 0.8rem;
            border-radius: 0.7rem;
            display: flex;
            border: 1px solid transp;
            &:hover{
                background: transparent;
                color: #fff;
                border: 1px solid;
                border-color: #faab02;
            }
        }

    }
    .footer_copyright{
        margin-bottom: 4rem;
        color: #3df7e7;
        a{
            color: #fff;  
        }
    }
}






/* ........ MEDIA QUERIES (SMALL DEVICES) ....... */
  
@media screen and (max-width: 600px) {
    .permanentlinks{
        flex-direction: column;
        gap: 1.5rem;
    }

    .footer_socials{
        margin-bottom: 2.6rem;
    }
   
  }
