 /*========================MEDIA QUERY==============================*/
 @mixin mobile{
    @media screen and (max-width: 600px) {
        @content;
    }
}
@mixin tablet {
    @media screen and (max-width: 768px) {
        @content;
    }
}
@mixin tablet2 {
    @media screen and (max-width: 690px) {
        @content;
    }
}
@mixin laptop {
    @media screen and (max-width: 1220px) {
        @content;
    }
}
@mixin laptop2 {
    @media screen and (max-width: 950px) {
        @content;
    }
}
@mixin mobile2 {
    @media screen and (max-width: 480px) {
        @content;
    }
}


.work-container{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 80%;
    height: 100%;
    margin: auto auto;
    margin-top: -20%;
    padding: 0;
    z-index: 1000;
    overflow: visible;
    background: #f4f7f5;
    line-height: 2rem;
    @include laptop2{
        width: 90%;
        margin-top: -25%;
       }
       @include tablet{
        margin-top: -25%;
    }
    @include tablet2{
        margin-top: -25%;
    }
    @include mobile{
        width: 95%;
        margin-top: -20%;
     }
     @include mobile2{
        margin-top: -20%;
     }
    
     .work-vission{
        margin: 0;
        height: 100%;
        width: 100%;
        padding: 5% 5%;
        
        h3{
            padding-bottom: 2rem;
            font-size: 26px;
            font-weight: 700;
            text-align: center;
        }
        p{
            font-size: 16px;
            font-weight: 600;
        }
        &:hover{
            h3{
                text-decoration: underline solid #f7a902;
                text-decoration-thickness: 7px;
                
            }
        }
     }

    .work-box{
        width: 100%;
        background: #ecb604;
        margin: 0;
        padding: 0;
        display: flex;
        gap: 2rem;
        height: 100%;
        align-items: center;
        justify-content: center;
        @include tablet{
           flex-direction: column;
           gap: 0;
         }
        .box-1{
            align-items: center;
            justify-content: center;
            padding: 7% 5%;
            margin: 0;
            height: 100%;
            width: 100%;
            h3{
                padding-bottom: 2rem;
                font-size: 25px;
                font-weight: 700;
                text-align: center;
            }
            
            ul{
                text-align: left;
            
                 li{
                list-style: disc;
                font-size: 16px;
                font-weight: 600;
            }
        }
            &:hover{
                h3{
                    text-decoration: underline solid #f0efee;
                    text-decoration-thickness: 7px;
                }
            }
            @include tablet{
                padding: 20% 10%;
              }
        }
        .box-2{
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 7% 5% ;
            align-items: center;
            justify-content: center;
            background: var(--blackColor);
            color: var(--whiteColor);
            h3{
                padding-bottom: 2rem;
                font-size: 25px;
                font-weight: 700;
                text-align: center;
            }
            ul li{
                font-size: 16px;
                font-weight: 600;
            }
            &:hover{
                h3{
                    text-decoration: underline solid #f7a902;
                    text-decoration-thickness: 7px;
                    
                }
            }
    
            @include tablet{
                padding: 20% 10%;
              }
        }
    }
    .work-title{
        display: flex;
        gap: 0.5rem;
        flex: start;
        align-items: center;
        span{
            background: #ecb604;
            height: 40px;
            width: 7px;
            margin-left: 5px;
        }
    }

    .work-on{
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 3rem;
        @include tablet{
            grid-template-columns: auto auto;
            gap: 2.5rem;
          }
          @include mobile(){
            grid-template-columns: auto;
            gap: 2rem;
          }
        article{
           display: block;
           position: relative;
           background: var(--whiteColor);
           padding-bottom: 20%;
           img{
            position: relative;
            width: 100%;
            height: 100%;
            @include mobile(){
                width: 90%;
               }
 
           }
           .icon-text{
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 66%;
              left: 40%;
              @include laptop(){
                gap: 0.4rem;
                top: 60%;
                left: 35%;
              }
              @include tablet{
                gap: 0.3rem;
                top: 65%;
              }
              @include tablet2{
                gap: 0.25rem;
                top: 65%;
              }
              @include mobile(){
               gap: 0.1rem;
               top: 70%;
              }

              i{
                background: #ecb604;
                padding: 8% 20%;
                @include laptop2{
                    padding: 12% 18%;
                   }
              }
              
           }
           &:hover{
            
            box-shadow: rgba(160, 155, 155, 0.8) 0px 40px 60px -16px,
            rgba(129, 75, 75, 0.72) 0px 30px 22px -10px;
            transform: scale(1.05);
            transition-duration: 300ms;
            .icon-text{
                span{
                    text-decoration: underline solid #f7a902;
                    text-decoration-thickness: 10px;
            
                      @include laptop2{
                        text-decoration-thickness: 7px;
                       }
                      
                  }
               }
           }
        }
       
    }

}