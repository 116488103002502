@mixin laptop2 {
    @media screen and (max-width: 1000px) {
        @content;
    }
}
@mixin tablet {
    @media screen and (max-width: 768px) {
        @content;
    }
}
@mixin mobile {
    @media screen and (max-width: 600px) {
        @content;
    }
}

.galary-container{
    margin-top: 2rem;
    
  .galary{
     display: grid;
     grid-template-columns: auto auto auto;
     gap: 2rem;
     padding-bottom: 3rem;
     padding-left: 1rem;
     padding-right: 1rem;
     @include laptop2{
      grid-template-columns: auto auto;
      gap: 2rem;
     }
     @include tablet{
        gap: 1rem;
       }
     @include mobile{
        grid-template-columns: auto;
       }
     figure{
        display: flex;
        flex-direction: column;
        .galary-img{
            width: 100%;
            height: auto;
        }
        .description{
            display: flex;
            flex-direction: column;
            figcaption{
                text-align: left;
            }
            h4{
                text-align: center;
            }
            span{
                text-align: center;
              padding: 1rem 0.5rem;
              @include laptop2{
                padding-top: 0.5rem;
               }
            }
        }
        &:hover{
            
            box-shadow: rgba(160, 155, 155, 0.8) 0px 40px 60px -16px,
            rgba(129, 75, 75, 0.72) 0px 30px 22px -10px;
            transform: scale(1.05);
            transition-duration: 300ms;
            
                h4{
                    text-decoration: underline solid #f7a902;
                    text-decoration-thickness: 7px;
                      
                  }
               
           }
     }
}
}