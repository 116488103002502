 /*========================MEDIA QUERY==============================*/
 @mixin mobile{
    @media screen and (max-width: 600px) {
        @content;
    }
}
@mixin tablet2 {
    @media screen and (max-width: 768px) {
        @content;
    }
}
@mixin tablet {
    @media screen and (max-width: 850px) {
        @content;
    }
}
@mixin laptop {
    @media screen and (max-width: 1100px) {
        @content;
    }
}
@mixin laptop2 {
    @media screen and (max-width: 950px) {
        @content;
    }
}
@mixin mobile2 {
    @media screen and (max-width: 480px) {
        @content;
    }
}


.container-about{
   display: flex;
   flex-direction: column;
   background: #d2d7d8;
   line-height: 2rem;
   margin: 0;
   padding: 0;
   
   .background{
    background-image: url('../../assets/haido13.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    
     .about{
        background: #fff;
        margin: 1% 3%;
        padding: 5%;
        margin-top: 25%;
        @include laptop{
            margin-top: 20%;
        }
        @include tablet2{
            margin-top: 30%;
        }
        @include mobile{
            margin-top: 40%;
        }
        h1{
            margin-bottom: 3rem;
            text-align: center;
        }
        h2{
            margin-bottom: 2rem;
            text-align: center;
        }
        p{
            text-align: left;
         }
         &:hover{
            h1{
              text-decoration: underline solid #f38f0d;
              text-decoration-thickness: 7px;
            }
            p{
                span{
                  text-decoration: underline solid #f38f0d;
                  text-decoration-thickness: 4px;
                }
            }
         }
     }
   }
   .card-container{
    display: flex;
    flex-direction: row;
    gap: 1.5%;
    margin-top: -2%;
    margin-bottom: 0;
    background: #f5f1f1;
    @include laptop2{
        flex-direction: column;
    }
    
    .implimentation{
        padding: 1%;
        background: #fff;
        margin: 1%;
        ul li{
            margin: 4%;
            text-align: left;
            list-style-type: disc;
        }
        &:hover{
            h2{
              text-decoration: underline solid #f38f0d;
              text-decoration-thickness: 6px;
            }
            ul li{
                span{
                  text-decoration: underline solid #f38f0d;
                  text-decoration-thickness: 4px;
                }
            }
         }
    }
    .mission-vision-achivment{
        display: flex;
        flex-direction: column;
        gap: .3rem;
        background: #f5f1f1;
        @include laptop2{
           display: grid;
           grid-template-columns: 1fr 1fr;
           gap: 1%;
         }
         @include laptop2{
            display: flex;
            flex-direction: column;
            gap: 2rem;
          }
       
        padding: 1rem;
        .vission{
            background: #0df3e0;
            padding: 2rem 1rem;
            margin: 1rem 2rem;
            @include laptop{
                padding: 1.5rem 0.7rem;
                margin: 0.8rem 1rem;
            }
           
            .icon{
                margin-top: 3%;
                margin-bottom: 1%;
                position: relative;
                left: 48%;
            }
            h2{
                margin-bottom: 2%;
                text-align: center;
            }
            &:hover{
                box-shadow: rgba(160, 155, 155, 0.8) 0px 40px 60px -16px,
                rgba(158, 150, 150, 0.72) 0px 30px 22px -10px;
                transform: scale(1.05);
                transition-duration: 300ms;
                background: #fff;
                    h2{
                        text-decoration: underline solid #ecb604;
                        text-decoration-thickness: 6px;
                    }
                    p{
                        span{
                            text-decoration: underline solid #ecb604;
                            text-decoration-thickness: 4px;
                        }
                    }
                }
            }
        
        .mission{
            background: #07a362;
            padding: 2rem 1rem;
            margin: 1rem 2rem;
            @include laptop{
                padding: 1.5rem 0.7rem;
                margin: 0.8rem 1rem;
            }
           
            .icon{
                margin-top: 3%;
                margin-bottom: 1%;
                position: relative;
                left: 48%;
            }
            h2{
                margin-bottom: 2%;
                text-align: center;
            }
            ul{
                margin: auto 2rem;
                line-height: 1.5em;
                li{
                    list-style-type: disc;
                    text-align: left;
                }
            }
            &:hover{
                box-shadow: rgba(160, 155, 155, 0.8) 0px 40px 60px -16px,
                rgba(158, 150, 150, 0.72) 0px 30px 22px -10px;
                transform: scale(1.05);
                transition-duration: 300ms;
                background: #fff;
                    h2{
                        text-decoration: underline solid #ecb604;
                        text-decoration-thickness: 6px;
                    }
                }
        }
        .achivment{
            background: #d162c2;
            padding: 2rem 1rem;
            margin: 1rem 2rem;
            @include laptop{
                padding: 1.5rem 0.7rem;
                margin: 0.8rem 1rem;
            }
           
            .icon{
                margin-top: 3%;
                margin-bottom: 1%;
                position: relative;
                left: 48%;
            }
            h2{
                margin-bottom: 2%;
                text-align: center;
            }
            ul{
                margin: auto 2rem;
                line-height: 1.5em;
                li{
                    list-style-type: disc;
                    text-align: left;
                }
            }
            &:hover{
                box-shadow: rgba(160, 155, 155, 0.8) 0px 40px 60px -16px,
                rgba(158, 150, 150, 0.72) 0px 30px 22px -10px;
                transform: scale(1.05);
                transition-duration: 300ms;
                background: #fff;
                    h2{
                        text-decoration: underline solid #ecb604;
                        text-decoration-thickness: 6px;
                    }
                }
        }
    }
   }
   Footer{
    margin-top: 0;
    background: #000;
   }
}