@mixin laptop {
    @media screen and (max-width: 1300px) {
        @content;
    }
}
@mixin tablet {
    @media screen and (max-width: 900px) {
        @content;
    }
}
@mixin tablet2 {
    @media screen and (max-width: 700px) {
        @content;
    }
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}
/*body{
    min-height: 100vh;
    width: 100%;
    font-size: 1rem;
   
    overflow-x:hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}*/
.body{
    min-height: 100vh;
    width: 100%;
    font-size: 1rem;
    background-image: url('../../assets/haido4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    color: #777;
    justify-content: center;
    align-items: center;
}
.home-body{
    height: 100vh;
    width: 100%;
   
    background-image: url('../../assets/haido4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    text-align: center;
    color: #000 !important;
    justify-content: center !important;

    h2{
        font-weight: bold;
        font-style: italic;
        font-size: 60px;
        padding-top: 5rem !important;
    }
    p{
        font-weight: 500;
        font-size: 20px;
        font-style: normal;
        letter-spacing: 0.2px;
        margin: 2rem 5rem 3rem;
        padding: 1rem 2rem 2rem;
    }

}
.event-container{
   
   width: 100%;
   display: grid;
    grid-template-columns: auto auto auto auto;
    margin: 1rem auto !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    padding: auto auto !important;
    gap: 2rem;
    @include laptop{
        grid-template-columns: auto auto auto;
    }
    @include tablet{
        grid-template-columns: auto auto;
    }
    @include tablet2{
        grid-template-columns: auto;
    }
    .card-container{
       position: relative;
       margin: 10px;
       width: 100% !important;
       height: 570px;
       overflow: hidden;
       background-color: #fff;
       box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.15);
       cursor: pointer;
    
       .img-container,
       .body-container {
           position: absolute;
           top: 0;
           left: 0;
           width: 100%;
           height: 100%;
      }
       .img-container{
           img{
            width: 100%;
            height: auto;
           }
      }
       .body-container{
        position: relative;

        .overlay{
            position: relative;
            width: 100%;
            height: 100%;
            background-color: rgba(24,83,122,0.6);
            opacity: 0;
            transition: height linear 0.4s, opacity linear 0.2s;
            
        }
        .event-info {
            background-color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 8px;

            .title {
               color: #18537a;
               font-size: 1.5em;
               font-weight: bold;
               letter-spacing: 1px;
               margin: 12px;
          }
          .info {
            letter-spacing: 0.5px;
            margin-bottom: 6px;
         }
         .separator {
            width: 20%;
            height: 6px;
            background-color: #17537a;
            margin-bottom: 16px;
         }
         .additional-info {
            border-top: 1px solid #bbb;
            margin-top: 12px;
            padding: 28px;
            padding-bottom: 0;

            .info {
                font-size: 0.9em;
                margin-bottom: 20px;
                text-align: center;

                .i {
                    color: #18537a;
                    font-size: 1.1em;
                    margin-right: 4px;
                }
                span {
                    color: #18537a;
                    font-weight: bolder;
                }
            }
         }
        }
        
       }
       &:hover{
        .body-container{
            .overlay{
                opacity: 1;
                height: 150px;
            }
        }
       }
    }
}
