@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@300;400;500&family=Montserrat:wght@500;700&family=Poppins:wght@300;400;500;600&display=swap');


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Spartan', sans-serif;
}

:root{
  --PrimaryColor: hsl(225, 50%, 48%);
  --SecondaryColor: hsl(180, 17%, 95%);
  --ColorOne: hsl(15, 74%, 70);
  --ColorTwo: hsl(28, 100%, 82%);
  --HoverColor: hsl(225, 56%, 59%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 12%);
  --textColor: hsl(240, 1%, 48%);
  --borderColor: hsl(0, 0%, 83);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --grayBg: hsl(0, 0%, 96%);
  --grayText: hsl(145, 145, 145);
  --inputColor: hsl(330, 12%, 97%);
  --boxShadow: box-shadow: rgba(0,0,0,0.1) 0px 10px 15px -3px,
       rgba(0,0,0,0.05) 0px 4px 6px -2px;
  --transition: 0.4s ease-in-out:;
}
:root{
  --color-bg: #1f1f38;
  --color--bg-variant: #2c2c6c;
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77,181,255,0.4);
  --color-white: #fff;
  --color-light: rgba(255,255,255,0.6);
 
  --transition: all 400ms ease;
 
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
 }

 html{
  scroll-behavior: smooth;
}
/*
body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  background: #fff ;
  color: var(--color-white);
  line-height: 1.7;
 
  
}

body{
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(#fff 50%, #0f883d);
  color: var(--color-white);
  line-height: 1.7;
  
  
}*/
